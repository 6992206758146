@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  background: #0468A6;
  /* background: linear-gradient(
    90deg,  
    #b37b04 20.63%,
    #026689 50.63%,    
    #6CAC16 80.33%
  ); */
  box-shadow: 0px 4.42184px 107.23px rgba(255, 86, 246, 0.51);
}
.btnIsi {
  background: #ce8a0c;
  /* background: linear-gradient(
    90deg,  
    #b37b04 20.63%,
    #026689 50.63%,    
    #6CAC16 80.33%
  ); */
  box-shadow: 0px 4.42184px 107.23px rgba(255, 86, 246, 0.51);
}

.btn:hover {
  color:white;  
  font-weight: bold;
  border: 3px solid white;
  background: #0468A6;;
  /* background: linear-gradient(
    90deg,    
   
    #6CAC16 20.63%,
    #026689 50.63%,
    #b37b04 80.33%
  ); */
}

.gradient{
background: linear-gradient(
    90deg, 
   
    #b37b04 20.63%,
    #026689 45.63%,
    #6CAC16 80.33%
   
    
  );
}
.active {
  background:  #F9A60D;
  border: 1px solid white;
  
}

.active {
  color: #fff;
  padding: 0.3rem;
  border-radius: 100%;
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-black bg-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btnIsi {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-me {
    @apply h-[56px] px-10 text-base bg-[#E69519];
  }
  .btnIsi-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#42A6E3] to-[#6CAC16] font-bold font-primary hover:from-[#6CAC16] hover:to-[#42A6E3];
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
    /* @apply lg:h-full justify-center flex items-center; */
  }
}
